//store

import { configureStore } from "@reduxjs/toolkit";
import { useSelector ,useDispatch ,TypedUseSelectorHook} from "react-redux";
import UserReducerSlice from "./reducers/UserReducer";



export const store = configureStore({
    reducer: {
        user: UserReducerSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            // Ignore these action types
            ignoredActions: ['your/action/type'],
            // Ignore these field paths in all actions
            ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
            // Ignore these paths in the state
            ignoredPaths: ['items.dates'],
        },
    }),
})

/**
 * returnType:可以获取返回值的类型，使代码更具可维护性和可读性，
 * 特别是在处理复杂的函数类型时，可以减少手动定义类型的工作，
 * 同时增加了代码的类型安全性。它在编写高度类型化的代码或使用泛型函数时特别有用。
 * */
 export type RootState = ReturnType<typeof store.getState>
 export type AppDispatch = typeof store.dispatch

//全局定义dispatch和state，增加类型注解
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
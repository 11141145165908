import { uploadObjectProps, msgProps , contentType ,UserinfoProps} from "../interface";
import {   uploadAudioRequest , uploadPhotoRequest } from "../api/api";

/**
 * 
 * @param token 设置用户token
 */
export const setToken = (token: string) => {
	localStorage.setItem("XY_token", token);
};

//获取token
export const getToken = () => {
	const token = localStorage.getItem("XY_token");
	return token;
};
//删除token
export const removeToken = () => {
	const token = localStorage.getItem("XY_token");
	if (token) {
		localStorage.removeItem("XY_token");
	}
};

//路由登录拦截
export const checkLogin = (pathname: string) => {
	const authRouter = ["/login", "/register"];
	if (!authRouter.includes(pathname)) {
		if (!getToken()) {
			return false;
		}
	}
	return true;
};

// 保存个人信息到本地
export const updateUserinfo = (data:any) => {
	let userinfo = localStorage.getItem("XY_userinfo");
	if(userinfo){
		localStorage.removeItem('XY_userinfo');
	}
	localStorage.setItem("XY_userinfo", JSON.stringify(data));
}

//获取个人信息
export const getUserinfo:()=>null | UserinfoProps = () => {
	let userinfo = localStorage.getItem("XY_userinfo");
	if(userinfo !== 'undefined' && userinfo){
		return JSON.parse(userinfo);
	}else{
		return null;
	}
};



//上传图片
// export const uploadPhoto = async (file: File, callback?: Function) => {
// 	const formData = new FormData();
// 	formData.append("file", file);
// 	try {
// 		const res = await uploadPhotoRequest(formData);
// 		if (res.code === 200) {
// 			callback && callback();
// 		}
// 	}
// }

//转换 y/m/d h:m:s格式
export const formatDate_noraml = () => {
	const date = new Date();
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0");
	const day = String(date.getDate()).padStart(2, "0");
	const hour = String(date.getHours()).padStart(2, "0");
	const minute = String(date.getMinutes()).padStart(2, "0");
	const second = String(date.getSeconds()).padStart(2, "0");
	return `${year}/${month}/${day} ${hour}:${minute}:${second}`;
};
//转换时间戳
export const getTimestampFromDateTimeString = (dateTimeString: string) => {
	const [datePart, timePart] = dateTimeString?.split(" ");
	const [year, month, day] = datePart.split("/").map(Number);

	const [hour, minute, second] = timePart.split(":").map(Number);
	return new Date(year, month - 1, day, hour, minute, second).getTime();
};
//格式化日期
export const formatDate = (date: string) => {
	const timeStamp = getTimestampFromDateTimeString(date);
	const now = new Date().getTime();
	const diff = Math.floor((now - timeStamp) / 1000); // 计算时间差，单位为秒
	if (diff < 60) {
		return "刚刚";
	} else if (diff < 3600) {
		const minutes = Math.floor(diff / 60);
		return `${minutes}分钟前`;
	} else if (diff < 86400) {
		const hours = Math.floor(diff / 3600);
		return `${hours}小时前`;
	} else if (diff < 172800) {
		return "昨天";
	} else if (diff < 604800) {
		const days = Math.floor(diff / 86400);
		return `${days}天前`;
	} else if (diff < 1209600) {
		return "上周";
	} else {
		return date.split(' ')[0];
	}
};


let mediaRecorder: any;
let mediaStream:MediaStream | any = null;
let recordedChunks: any[] = [];
let recordError:boolean = false //录制错误
let recordTime:number = 0 //ms
let timer:any = null;
//开始录制语音消息
export const beginRecordAudio = () => {
	recordError = false
	recordTime = 0
	timer = null
	navigator.mediaDevices.getUserMedia({ audio: true })
		.then(function (stream) {
			// 创建 MediaRecorder 对象
			mediaStream = stream
			try {
				mediaRecorder = new window.MediaRecorder(stream);
				console.log('mediaRecorder',mediaRecorder);
				
			} catch (err1) {
				try {
					mediaRecorder = new window.MediaRecorder(stream,{mimeType: 'video/webm'});
				} catch (err2) {
					console.log('不支持',err2,mediaRecorder);
						
				}
			}
			
			//统计录制时长
			if(!timer){
				timer = setInterval(()=>{
					recordTime+=10
				},10)
			}
			// 监听录制的音频数据可用事件
			mediaRecorder.ondataavailable = function (event: any) {
				console.log('正在录制');
				
				//如果录制太短就结束录制
				if(recordError){
					mediaRecorder.stop();
					return
				}
				if (event.data.size > 0) {
					recordedChunks.push(event.data);
				}
			};
			// 开始录制
			mediaRecorder.start(1000); //非常重要，开启录制时，每隔1000ms就执行一次ondataavailable事件
		})
		.catch(function (err) {
			console.log('获取音频流失败：', err);
		});
}

//结束录制语音消息
export const endRecordAudio: () => Promise<any> = () => {

	return new Promise((resolve, reject) => {

		if(recordTime < 1000) {

			recordError = true
			resolve({msg:'录制时间太短！',code:-1})

		}else if (mediaRecorder && mediaRecorder.state !== 'inactive') {

			mediaRecorder.stop();
			// 关闭麦克风
			if (mediaStream) {
				mediaStream.getTracks().forEach((track:MediaStreamTrack) => track.stop());
			}
			// 释放 MediaStream 对象
			mediaStream = null;
			
			//录制结束后，ondataavailable会有一个回调，所以要加一个异步等他回调，再请求
			setTimeout(() => {
				clearInterval(timer)
				const audioBlob = new Blob(recordedChunks, { type: 'audio/webm' });
				let formData = new FormData();
				formData.append('audio', audioBlob,recordTime / 1000 +'s');
				console.log(recordTime)
				// 使用 Axios 发送音频数据给后端
				uploadAudioRequest('/uploadsAudio',formData)
					.then(function(res) {
						if(res.status === 200){
							resolve({code:1,url:res.data.url})
							recordedChunks = []; // 重置录制的音频数据
						}
					})
					.catch(function(error) {
						resolve({code:-1,msg:'录制语音失败！请稍微再试'})
						console.log('音频上传失败：' + error);
					});

			},200)

		}
	})

}

//上传图片
export const handlerUploadPhoto:(file:File) => Promise<uploadObjectProps> = (file) => {
	let formData = new FormData();
	formData.append('photo',file,file.name)
	return new Promise((resolve,reject) => {
		uploadPhotoRequest('/uploadsPhoto',formData).then((res) => {
			if(res.status === 200){
				resolve(res.data)
			}

		}).catch((err) => {
			console.log('上传图片出错',err)
			resolve({code:-1,msg:'发送图片出错！请稍后再试'})
		})
	})

}


//智能小寻
export const updateMsgToLocal = (list?:msgProps[]) => {
	//获取本地数据
	 let msgList = JSON.parse(sessionStorage.getItem('msgList') as any)
	
	if(list){
		if(msgList){
			msgList = msgList.filter((item:msgProps) => item.roles !== 'loading')
			let newList = [...msgList,...list]
			sessionStorage.setItem('msgList',JSON.stringify(newList))
	
		}else{
			sessionStorage.setItem('msgList',JSON.stringify(list))
		}
	}

	return JSON.parse(sessionStorage.getItem('msgList') as any)
}

export const getMsgList = () => {
	let msgList = JSON.parse(sessionStorage.getItem('msgList') as any)
	
	if(!msgList){
		updateMsgToLocal([
			{
				roles:'assistant',
				content: '欢迎使用智能小寻',
				type:contentType.text
			}
		])
		msgList = JSON.parse(sessionStorage.getItem('msgList') as any)
	}
	return msgList || []
}


import { BrowserRouter as Router,Route,Routes,useNavigate } from 'react-router-dom';
import React,{ useRef ,useState,useEffect ,useContext} from 'react';
import { useAppSelector ,useAppDispatch} from './store';
import './App.css';
import useToast from './hooks/useToast';
import Login from './components/Login';
import Register from './components/Register';
import Layout from './components/Layout';
import RenderRouter from './router/index';
import routes from './router/routes';


function App() {
	
	return (
		// 这里做个判断 来条件渲染 登录或layout
		<div>
			<Router>
				{/* <Routes>
					<Route path='/login' element={<Login/>}></Route>
				</Routes> */}
				<RenderRouter />
				
			</Router>
		</div>
		
		
	);
}

export default App;

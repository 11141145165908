import { Socket } from "socket.io-client";

// 解决ts类型报错问题
declare global {
  export interface Window {
     MediaRecorder: any;
     mozIndexedDB: any;
     webkitIndexedDB: any;
     msIndexedDB: any
   }
 }

//消息发送方
export enum MsgStatus {
  '发送中'= '发送中',
  '发送失败'= '发送失败',
  '未读'= '未读',
  '已读'= '已读'
}
//消息内容类型
export enum MsgType {
    'normal' = '文本消息',
    'audio' = '语音消息',
    'image' = '图片消息'
}
//消息列表
export interface ChatListProps {
  avatar: string;
  title: string;
  receiver_id: number;
  chatList:ChatProps[];
  unread_num: number;
  total: number;
  chat_id: number;
}

//消息内容
export interface ChatProps {
  id:number,
  chat_id?: number;
  user_id: number;
  receiver_id: number;
  content?: string;
  time: string;
  isRead: MsgStatus;
  isRecall?:number;
  is_deleted?:number;
  msgType:MsgType;
  audioTimes?:string;
}

//登录参数
export interface loginProps {
  username: string;
  password: string;
}
//注册参数
export interface registerProps {
  username: string;
  password: string;
  gender: string;
}

//store的initstate
export interface storeState {
  token?: string | null;
  pathname: string;
  online: boolean;
  msg?: string;
  userinfo: UserinfoProps | any;
  onlineUsers: UserinfoProps | any;
  socket?: Socket | any;
  chatlist: ChatProps[];
  videoElementInfo: any;
  unreadMsg: string;
  msglist:ChatListProps[];
}
//用户基本信息
export interface UserinfoProps {
  user_id: number;
  avatar: string;
  birth: string;
  region: string;
  username: string;
  signature: string;
  constellation: string;
  otherinfo: WorksInfoProps;
}
//关注，点赞，喜欢
export interface WorksInfoProps {
  user_id: string;
  follow: number;
  like: number;
  praise: number;
  works: number;
  fans: number;
}

//上传图片/音频类型
export interface uploadObjectProps{
  code: number,
  msg: any,
  url?: string
}

// 视频组件类型
export interface CallProps{
  peer?: any,
  socket?: any,
  onClose:() => void,
  callInfo:any,
  type:string,
  myVideoRef:any,
  otherVideoRef:any,
  isAccept:boolean,
  isVideo:boolean,
  calling:boolean,
}

// 高德组件类型
export interface AmapProps{
  onClose:() => void,
  onSend:() => void,
}

// 聊天组件-聊天展示区域
export interface ChatBodyCenterProps{
  chatList:ChatProps[],
  setChatList:any,
  userinfo:UserinfoProps,
  receiverinfo:ChatListProps,
  recallChatRecord:(chat: ChatProps) => void
}

// 通话信息
export interface callInfoProps{
  caller_id:number,
  caller_avatar:string,
  caller_name:string,
  receiver_id:number,
  receiver_avatar:string,
  receiver_name:string,
  peer_id:string,
  type:string
}

//智能小寻
export enum contentType{
  code = '代码',
  multiText = '多行文字', // 含有 \n\n
  singleText = '单行文字', // 含有 \n
  text = '普通文字'
}
export interface msgProps{
  roles: string,
  content: string,
  type: contentType
}

// 发布帖子
export interface iPostsProps{
  id: string,
  author: string,
  avatar: string,
  signature?: string,
  title?: string,
  photos?: string[]
}


//在线匹配信息
export interface matchInfoProps{
  user_id:number,
  avatar?:string,
  username:string,
  constellation?:string,
  gender:string,
  region?:string,
  peer_id:string | number,
  socket_id:string | number
}
import { getChatlistRequest , synChatRecordRequest } from "../api/api";
import { ChatProps,ChatListProps } from "../interface";
import { getUserinfo } from "./index"

// 在浏览器上创建 indexedDB数据库，用于存储聊天记录

type mytableProps = {
    init?:Function,
    chatrecord?:Function,
    AIchatrecord?:Function,
}

type InitOptions = {
    id?:number,
    user_id?:number,
    receiver_id?:number,
    type?:string,
    data?:any,
    msgStatus?:string,
    first_id?:number,
    page_size?:number,
    page_name:string,
}

class IDB{
    avaiable:boolean
    db:any
    user_id:number
    pageSize:number
    data:ChatProps[]
    update_flag:boolean

    constructor(){
        this.avaiable = true;
        this.db = null;
        this.user_id =  0
        this.pageSize = 20;
        this.data = []
        this.update_flag = false
    }

    use(options:InitOptions):Promise<any>{
        const indexedDB:IDBFactory & mytableProps = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        return new Promise((resolve,reject) => {
            if(indexedDB){
                // 创建聊天记录数据库---默认不传入版本号，版本号会自动兼容
               const request = indexedDB.open('xunyou')
               let self = this;
               const {user_id,type,data,receiver_id, msgStatus,first_id,page_name} = options;

               request.onerror = function(e){
                   console.log('创建数据库错误',e.target && e.target);
               }
               request.onsuccess = async function(e:any){
                   self.user_id = user_id || 0;
                   self.db = e.target.result;
                    // 同步聊天记录
                    // self.chatrecord()
                    console.log('请求页面',page_name,'请求方法',type);
                    
                    // 手动添加聊天记录
                    switch (type) {
                        case 'add': // 添加聊天记录
                            self.add('chatrecord',data);
                            break;
                        case 'update': // 更新聊天记录
                            await self.update('chatrecord',false,data,msgStatus,user_id,receiver_id)
                            resolve('更新成功');
                            break;
                        case 'get': // 获取聊天记录
                            let res = await self.get('chatrecord',user_id,receiver_id,first_id)
                            resolve(res)
                            break;
                        case 'patch': //同步聊天记录
                            self.patch('chatrecord',data)
                            break;
                    }
                }
                request.onupgradeneeded = function(e:any){
                    if(!self.db){
                        self.db = e.target.result;
                    }
                    // 创建聊天记录表
                    if(!self.db.objectStoreNames.contains('chatrecord')){
                        self.db.createObjectStore('chatrecord',{keyPath:'id'});
                    }
                    
                    // 创建AI聊天记录表
                    // if(!self.db.objectStoreNames.contains('AIchatrecord')){
                    //     self.db.createObjectStore('AIchatrecord',{keyPath:'id'});
                    // }
                }
            }else{
                this.avaiable = false;
                reject(new Error('浏览器不支持indexedDB'))
            }
        })
    }

    /**
     * 向指定表中添加数据
     *
     * @param table 要添加数据的表名
     */
    add(table:string,data:any){
        let transaction = this.db.transaction([table],'readwrite');
        console.log('add',data);
        let objectStore = transaction.objectStore(table);
        let list = data
        if(data.__proto__ !== Array.prototype){
            list = [data]
        }
        
        list.forEach((item:any) => {
            let req = objectStore.add(item);

            req.onerror = function(event:any){
                console.log('写入失败',event.target.error);
            }
        })
    }

    /**
     * 更新聊天列表
     *
     * @param table 表格名称
     * @param isBatch 是否为批量更新
     */
    async update(table:string,isBatch?:boolean,data?:any,msgStatus?:string,userID?:number,receiverID?:number){
        console.log('执行update',data);
      
            if(isBatch){
                // 批量获取聊天记录
                getChatlistRequest('/chat/chatlist',{userID:this.user_id,receiverID:null}).then(res => {
                    console.log('请求成功',res);
                    let msglist = res.data.data;
                    if(res.data.code === 200){
                        msglist.forEach((item:ChatListProps) => {
                            const { total,chat_id,receiver_id } = item
                            this.batchGetChatrecord(table,chat_id,receiver_id,total)
                        })
                    }
                })
            }
           // 需要更新已读状态
            if(msgStatus && msgStatus === '已读'){
               let chatlist = await this.get(table,userID,receiverID)
               for (const item of chatlist) {
                    if (item.isRead === '未读') {
                        try {
                            console.log('update-msg', item);
                            // 开始事务
                            const transaction = this.db.transaction([table], 'readwrite');
                            const store = transaction.objectStore(table);
                
                            // 异步执行 put 操作
                            await new Promise((resolve, reject) => {
                                const request = store.put({...item, isRead: '已读' });
                                request.onsuccess = (event: any) => {
                                    console.log('更新成功');
                                    resolve('更新成功');
                                };
                                request.onerror = (event: any) => {
                                    console.log('更新失败', event.target.error);
                                    reject(event.target.error);
                                };
                            });
                        } catch (error) {
                            console.log('put-error', error);
                        }
                    }
                }
            }else{
                 // 开始事务
                const transaction = this.db.transaction([table], 'readwrite');
                const store = transaction.objectStore(table);
                // 更新其他状态
                console.log('更新其他状态',data);
                if(data){
                    let list = data
                    if(data.__proto__ !== Array.prototype){
                        list = [data]
                    }
                    list.forEach((item:any) => {
                        let val = {...item}
                        // 说明这条消息已经有一方删除了，-1则是双方都删除
                        if(item.is_deleted && item.is_deleted !== 0){
                            val.is_deleted = -1
                        }
                        let req = store.put(val);
            
                        req.onerror = function(event:any){
                            console.log('更新失败',event.target.error);
                        }
                    })
                }
            }
    }

    /**
     * 从IndexedDB数据库中获取指定表的数据
     *
     * @param table 表名
     * @param userID 用户ID，可选参数
     * @param receiverID 接收者ID，可选参数
     * @param page 页码，默认为0
     * @returns 返回一个Promise对象，解析后得到查询结果
     */
    get(table:string,userID?:number,receiverID?:number,first_id:number = 0 ):Promise<any>{
        let transaction = this.db.transaction([table])
        let store = transaction.objectStore(table);
        let cursorReq:any = null
        if(first_id){
             // 设置查询范围
            let range = IDBKeyRange.upperBound(first_id, true);
            cursorReq = store.openCursor(range,"prev");
        }else{
            let range = IDBKeyRange.lowerBound(0); // 开始于最大值
            cursorReq = store.openCursor(range,"prev");
        }
        let i = 0
        let self = this
        let result:ChatProps[] = []
        let userinfo = getUserinfo()
        if(userinfo){
            userID = userID || userinfo.user_id
        }
        console.log('get',userID,receiverID);
        return new Promise((resolve,reject) => {
            cursorReq.onsuccess = function(event:any){
                let cursor = event.target.result;
                
                if(cursor){
                    var record:ChatProps = cursor.value;
                    const {id, user_id,receiver_id ,is_deleted} = record
                    // 此处查询俩个人的详情记录-场景在chat组件上
                    if(userID && receiverID){
                        // 查找符合要求的记录
                        if((user_id === userID || user_id === receiverID) && 
                         (receiver_id === userID || receiver_id === receiverID) &&
                         (is_deleted !== userID && is_deleted !== -1)){
                            result.push(record)
                        }
                    }else{ 
                        // 查询该用户的全部聊天记录--场景在mesage组件上
                        if(receiver_id === userID){
                            if(result.length < self.pageSize){
                                result.push(record)
                                i++
                            }
                        }
                    }
                    cursor.continue();
                }else{
                    // 切片返回
                    console.log('查询结果',result);
                    result = result.slice(0, self.pageSize)
                    resolve(result.reverse())
                }
            }
            cursorReq.onerror = function(event:any){
                console.log('读取失败',event.target.error);
                reject(event.target.error)
            }
        })
        
    }


    async patch(table:string,data?:any){
        let transaction = this.db.transaction([table])
        let store = transaction.objectStore(table);
        let self = this
        data.forEach((chat:ChatListProps) => {
            let msg = chat.chatList[0]
            if(msg){
                store.get(msg.id).onsuccess = async function(event:any){
                    let value = event.target.result
                     
                    if(!value){
                        //发送请求 更新聊天记录
                       let res = await synChatRecordRequest('/chat/synChatlist',{chatID:msg.chat_id || 0,ID:msg.id})
                    //    把结果追加到indexedDB中
                        self.add(table,res.data.data)
                    }
                }
            }
        })
        
    }

    // 批量获取聊天记录-进行保存
    batchGetChatrecord(table:string,chat_id:number,receiver_id:number,total:number){
        // 获取接收方id
        if(receiver_id === this.user_id){
            const [a,b] = String(chat_id).split(String(this.user_id))
            receiver_id = Number(a) === this.user_id ? +b : +a
        }
        for (let index = 0; index < Math.ceil(total / 20); index++) {
            getChatlistRequest('/chat/chatlist',{userID:this.user_id,receiverID:receiver_id}).then(res => {
                console.log('聊天记录',res.data.data);
                res.data.data.forEach((item:ChatProps) => this.add(table,item))
            })  
        }
        
    }

    chatrecord(){
        // 更新数据
        this.update('chatrecord',true)
    }
}






const indexedDB = new IDB();

export default indexedDB
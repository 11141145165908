
import { Skeleton } from "antd-mobile"
import styles from './skeleton.module.css'

// 首页骨架屏
export const HomeSkeleton = () => {
    return (
        <div className={styles.home} style={{'padding':'0 0.625rem'}}>
			<div className={styles.content}>
				<Skeleton.Title animated />
				<div className={styles.item}>
					<Skeleton.Title animated style={{'width':'100%'}} />
					<Skeleton.Paragraph lineCount={1} animated />
					<Skeleton animated style={{'--width':'60%','--height':'100px','--border-radius':'8px'}} />
					<Skeleton.Paragraph lineCount={1} animated />
				</div>
				<div className={styles.item}>
					<Skeleton.Title animated style={{'width':'100%'}} />
					<Skeleton.Paragraph lineCount={1} animated />
					<Skeleton animated style={{'--width':'60%','--height':'100px','--border-radius':'8px'}} />
					<Skeleton.Paragraph lineCount={1} animated />
				</div>
				<div className={styles.item}>
					<Skeleton.Title animated style={{'width':'100%'}} />
					<Skeleton.Paragraph lineCount={1} animated />
					<Skeleton animated style={{'--width':'60%','--height':'100px','--border-radius':'8px'}} />
					<Skeleton.Paragraph lineCount={1} animated />
				</div>
			</div>
		</div>
    )
}

// 消息列表骨架屏
export const MessageSkeleton = () => {
    return (
        <div className={styles.message} style={{'padding':'0 0.625rem'}}>
				<Skeleton.Title animated />
				<div className={styles.item}>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
					<div style={{'width':'55%'}}>
                        <Skeleton.Paragraph lineCount={1} animated style={{'width':'30%'}} />
                        <Skeleton.Paragraph lineCount={1} animated />
                    </div>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
				</div>
				<div className={styles.item}>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
					<div style={{'width':'55%'}}>
                        <Skeleton.Paragraph lineCount={1} animated style={{'width':'30%'}} />
                        <Skeleton.Paragraph lineCount={1} animated />
                    </div>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
				</div>
                <div className={styles.item}>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
					<div style={{'width':'55%'}}>
                        <Skeleton.Paragraph lineCount={1} animated style={{'width':'30%'}} />
                        <Skeleton.Paragraph lineCount={1} animated />
                    </div>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
				</div>
				<div className={styles.item}>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
					<div style={{'width':'55%'}}>
                        <Skeleton.Paragraph lineCount={1} animated style={{'width':'30%'}} />
                        <Skeleton.Paragraph lineCount={1} animated />
                    </div>
					<Skeleton animated style={{'--width':'20%','--height':'50px','--border-radius':'8px'}} />
				</div>
		</div>
    )
}
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { Toast } from "antd-mobile";
import { getToken, removeToken } from "../utils";

// 创建 Axios 实例
const instance: AxiosInstance = axios.create({
  // baseURL: 'http://localhost:3000/api',
  baseURL: "https://xunyou.fun/api",
  timeout: 5000 * 3,
  withCredentials: false,
});

interface RequestItem {
  url: string | undefined;
  method: string | undefined;
  data: any;
}

// 创建请求队列
class RequestQueue {
  post_queue: Array<RequestItem>;
  get_queue: Map<string, any>;
  constructor() {
    // get请求队列
    this.get_queue = new Map();
    // post请求队列
    this.post_queue = [];
  }
  add(config: RequestItem) {
    
    if (config.method === "post") {
       
      return true;
    }
    
    // 判断是否已经存在了，否则添加到队列中
    if (config.url && !this.get_queue.has(config.url)) {
        this.get_queue.set(config.url, config);
        return true;
    }
    return false;
  }
  remove(config: RequestItem) {
    if (config.method === "post") {
      return;
    }
    if (config.url && this.get_queue.has(config.url)) {
      this.get_queue.delete(config.url);
    }
  }
}

let requestQueue = new RequestQueue();

// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    const { method, url, data } = config;

    // 在请求发送之前，可以进行一些处理，比如添加认证信息等
    //添加请求头
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    let isAdd = requestQueue.add({ method, url, data });
    // 所有请求都会进入这里，除了 下面的这个接口，因为有点特殊
    if (
      !isAdd &&
      !config.url?.includes("/users/getUserinfo")
    ) {
        console.log(isAdd, config.url);
        
    //   return Promise.reject("");
    }
    if (config.url?.includes("Audio") || config.url?.includes("Photo")) {
      config.baseURL = "https://xunyou.fun/api";
    }

    return config;
  },
  (error) => {
    return error.response;
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response) => {
    const {
      config: { method, url, data },
    } = response;
    // 对响应数据进行处理
    requestQueue.remove({ method, url, data });
    console.log(response);
    return response;
  },
  (error) => {
    const {
        config: { method, url, data },
      } = error;
    requestQueue.remove({ method, url, data });

    if (error.response.data.msg.includes("token")) {
      removeToken();
    }
    console.log(error);
    return error.response;
  }
);

export default instance;

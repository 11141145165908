import React, {createContext, Provider, useState, useEffect, useContext,useMemo, useRef} from "react";
import { io,Manager } from "socket.io-client";
import { Peer } from "peerjs";
import {Toast ,SpinLoading , FloatingBubble } from "antd-mobile";
import { FloatButton } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import {useAppDispatch, useAppSelector} from "./store";
import { AsyncGetUserinfo ,AsyncGetOnlineUser ,AsyncGetChatList } from "./store/actionAPI";
import { updateUnreadMsg } from './store/reducers/UserReducer'
import { getToken } from "./utils";
import indexedDB from "./utils/indexedDB";
import useToast from "./hooks/useToast";
import { MsgStatus, callInfoProps ,ChatProps, CallProps} from "./interface";
import Call from "./components/Call";

const SocketContext = createContext<any>(null);
let development:string = 'http://localhost:2001'
let product:string = 'https://xunyou.fun'

export { SocketContext };

//临时存放用户信息
let tempUserinfo:any = {}

const WebSocket:React.FC<any> = ({children}) => {
    const dispatch = useAppDispatch()
    const [userinfo,setUserinfo] = useState<any>()
	const [isConnect,setIsConnect] = useState(false)
	const {toast} = useToast()
	const [socket,setSocket] = useState<any>(null)
	const [peer,setPeer] = useState<any>(null)
	const [isOpenCall,setIsOpenCall] = useState(false)
	const [callInfo,setCallInfo] = useState<callInfoProps | any>()
	const [type,setType] = useState<string>('')
	const [isVideo,setIsVideo] = useState<boolean>(false)
	const [isAccept,setIsAccept] = useState<boolean>(false)
	const [calling,setCalling] = useState<boolean>(true)
	const myVideoRef = useRef<MediaStream | any>(null)
	const otherVideoRef = useRef<MediaStream | any>(null)
    const unread = useAppSelector(state => state.user.unreadMsg)
	const [msgList,setMsgList] = useState<any>([])
	const pathname = useAppSelector(state => state.user.pathname)
	// 此处 主要进行连接socket和请求用户信息操作
	useEffect(() => {

		// 连接函数
		const connectFun = async () => {
			
			// const manager = new Manager(product);
			// const _socket = manager.socket("/chat"); // main namespace
			const _socket = io(product);
			/**
			* 封装一个socket中间件
			*
			* @param name 消息名称
			* @param data 消息数据
			* @param callback 回调函数
			*/
			class SocketMiddler {
				public name:string = ''
				public data:Object = ''
				public socket:any = _socket
				public timer:any = null
				public timeout:number = 500
				public max_request_times:number = 25
				public callback:Function = () => {}
				public count:number = 0
				
				emit(name:any,data:any,callback?:Function){

					if(name || callback){
						// 第一次请求 主要检查是否连接服务端，并进行保存数据
						this.name = name 
						this.data = data || ''
						this.callback = callback as any
						clearInterval(this.timer)
						// 频繁进行请求，防止请求超时，进行定时器，当超过25次的时候，进行提示
						this.timer = setInterval(() => {
							if(this.count > this.max_request_times){
								clearInterval(this.timer)
								Toast.show('请求超时，请检查网络再试')
								this.callback(false)
								// 发送失败，进行更新状态
								this.socket.emit(this.name, {...this.data,isRead:MsgStatus.发送失败})
								window.location.reload()
								return
							}else{
								console.log('请求中',this.count,this.timer)
								this.socket && this.socket.emit('request')
								this.count++
							}
						}, this.timeout);
						return 	
					}
					// 第二次 请求，说明连接服务端了，进行发送数据
					// console.log('请求成功',this.timer)
					this.count = 0
					clearInterval(this.timer)
					this.socket.emit(this.name, this.data)
					this.callback && this.callback(true)
				}

				on(name:string,callback:Function){
					this.socket.on(name,callback)
				}

				off(name:string){
					if(!name){
						this.socket.off()
					}else{
						this.socket.off(name)
					}
				}
			}
			
			//监听用户连接
			_socket.on('connect', async() => {
				// console.log('Connected to server,出现加载动画');
			});
			let socketObj = new SocketMiddler()
			setPeer(new Peer())
			setSocket(socketObj)
			// setMySocket(socketObj)
		}
		//判断是否是登录状态
		if(getToken()){
			// 判断当前是否已经连接了，没有的话，就进行连接
			console.log('登录状态');
			
			if(!socket){
				console.log('触发连接');
				connectFun()
			}
			if(peer){
				//绑定用户id
				peer.on('open', async (id:any) => {
					console.log('My peer ID is: ' + id,'unread',unread);
					setIsConnect(true)
					const userinfoRes = await dispatch(AsyncGetUserinfo())

					if(userinfoRes.payload.code === 200){
						setUserinfo(userinfoRes.payload.data)

						// 上线的时候，如果未读消息是空，默认请求一次
						if(unread === ''){
							dispatch(AsyncGetChatList({userID:userinfoRes.payload.data.user_id,receiverID:null})).then(res=>{
								setMsgList(res.payload.data)
								dispatch(updateUnreadMsg(res.payload.data))
							})
						}
						socket.emit('createConnect', { user_id: userinfoRes.payload.data.user_id , peer_id: id });
					}
				});
			}
		}else{
			// 移出骨架屏，跳转登录界面
			setIsConnect(true)
		}
		return () => {
			// console.log('unmount');
		 }
	}, [peer,socket]);

	// 关闭所有通话配置
	const closeAllCall = () => {
		setIsOpenCall(false)
		setIsAccept(false)
		setCalling(true)
		//关闭视频通话
		myVideoRef.current.srcObject?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
	}

	//打开自己的摄像头/麦克风
	const openVideoOrAudio = (data:callInfoProps,type:string) => {
		let opt = {}
		let _callInfo:any = {}
		if(data.type === 'audio'){
			opt = {video: false, audio: true}
		}else{
			opt = {video: true, audio: true}
		}
		if(!callInfo){
			_callInfo = {...data}
		}else{
			_callInfo = {...callInfo}
		}
		
		navigator.mediaDevices.getUserMedia(opt).then(
			function (_stream: MediaStream) {
				if(type === 'self'){
					const call = peer.call(_callInfo.peer_id, _stream);
					socket.emit('call', _callInfo);
					//监听对方的视频资源
					call.on("stream", (remoteStream:any) => {
						console.log('已经拨打成功，对方视频即将显示',remoteStream)
						otherVideoRef.current.srcObject = remoteStream
						setCalling(false)
						setIsAccept(true)
					});
				}
				myVideoRef.current.srcObject = _stream
				console.log('自己的摄像头',myVideoRef.current,_stream);
			}
		).catch(function (error:any) {
			console.error('无法访问摄像头:', error);
			setIsOpenCall(false)
			setIsAccept(false)
			setCalling(true)
			Toast.show('无法访问摄像头！请检查权限或刷新页面重试！')
		});
	}

	//关闭通话
	const closeCall = () => {
		socket.emit('closeCall',callInfo.receiver_id)
		//关闭视频通话
		// myVideoRef.current.srcObject?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
		closeAllCall()
		console.log('close...parent',myVideoRef.current.srcObject?.getTracks(),myVideoRef,userinfo)
	}

	

	// 因为请求是异步，所以通过监听isConnect变量，监听到了在进行判断
	useEffect(() => {
		console.log('useEffect-peer',peer,userinfo);
		
		if(socket || (userinfo && userinfo.user_id)){
			
			socket && socket.on('response',() => {
				// console.log('响应成功-socket',socket);
				socket.emit()
			})
			
			// 监听注册用户独有的消息
			socket && socket.on('sayHello',(msg:any) => {
				toast(msg)
				// 保存信息到本地
				indexedDB.use({user_id:userinfo?.user_id,type:'add',data:msg,page_name:'socket'})
				console.log(msg);
				dispatch(updateUnreadMsg([msg]))
			})

			socket && socket.on('online',async () =>{
				// 获取在线用户
				await dispatch(AsyncGetOnlineUser())
			})

			//监听用户发送的消息
			socket && socket.on('message', async (msg:ChatProps) => {
				toast(msg)
				console.log('socket',msg,userinfo,window.location,pathname);
				// 保存信息到本地
				indexedDB.use({user_id:userinfo?.user_id,type:'add',data:msg,page_name:'socket'})
				// 说明用户当前在聊天页面，需要更新消息状态，防止用户退出当前页面后，消息状态显示错误问题
				if(window.location.pathname === '/message/chat'){
					indexedDB.use({user_id:msg.user_id,receiver_id:msg.receiver_id,type:'update',msgStatus:"已读",page_name:'socket'})
				}
				// 更新未读消息
				let chatlist = await indexedDB.use({user_id:msg.user_id,receiver_id:msg.receiver_id,type:'get',page_name:'socket'})
				console.log('更新未读消息',chatlist);
				dispatch(updateUnreadMsg(chatlist))
			});
			socket && socket.on('connect_error', (error:any) => {
				Toast.show('网络断开连接')
				console.error('Connection error:', error);
			  });
			socket && socket.on('openCall', (data:callInfoProps) => {
				console.log('打开音频组件',data)
				
				setCallInfo(data)
				setType('self')
				openVideoOrAudio(data,'self')
				setIsOpenCall(true)

				if(data.type === 'video'){
					setIsVideo(true)
				}else{
					setIsVideo(false)
				}
			})
			
			// 监听通话
			socket && socket.on('incomingCall',(data:callInfoProps) => {
				console.log(data.type === 'video','incomingCall')
				if(data.type === 'video'){
					setIsVideo(true)
				}else{
					setIsVideo(false)
				}
				setCallInfo(data)
				setIsOpenCall(true)
				setType('other')
				tempUserinfo = {...data}

			})
			
			//监听对方挂断电话
			socket && socket.on('closedCall',() => {
				console.log('挂断电话',myVideoRef.current.srcObject,otherVideoRef.current.srcObject)
				// setIsOpenCall(false)
				//关闭视频通话
				// myVideoRef.current.srcObject?.getTracks().forEach((track: MediaStreamTrack) => track.stop());
				// setIsAccept(false)
				// setCalling(true)
				Toast.show('对方已挂断电话')
				closeAllCall()

			})
			//监听是否断开连接
			socket && socket.on('disconnect', () => {
				Toast.show('网络断开连接')
				console.log('Disconnected from server');
			});

			// 监听视频是否拨打...
			peer.on("call", (call:any) => {
				console.log('peer.on',isVideo,tempUserinfo);
				// 如果是在线匹配，需要return
				if(window.location.href.includes('/mate')){
					return
				}
				setCallInfo(tempUserinfo)
				setType('other')
				let opt = {}
				if(tempUserinfo.type === 'audio'){
					opt = {video: false, audio: true}
				}else{
					opt = {video: true, audio: true}
				}
				console.log('opt',opt);
				navigator.mediaDevices.getUserMedia(opt).then(
					function (_stream: MediaStream) {
						// 创建 Peer 连接
						console.log(myVideoRef.current,_stream);
						myVideoRef.current.srcObject = _stream;
						//是否接听对方的视频通话
						(document.getElementById('answerCall') as any).addEventListener('click', () => {
							//如果接收方选择接听，则调用answer方法，传递本地视频流
							call.answer(_stream);
							console.log('接听了');
						});
						
						(document.getElementById('closeCall') as any).addEventListener('click', () => {
							//如果接收方选择接听，则调用answer方法，传递本地视频流
							call.close();
							setIsOpenCall(false);
							setIsAccept(false)
							//关闭视频通话
							let myVideo = document.getElementById('myVideo')
							if(myVideo){
								(myVideo as any).srcObject.getTracks().forEach((track: MediaStreamTrack) => track.stop());
							}
							console.log('拒绝了',myVideo,myVideoRef,tempUserinfo);
							socket.emit('closeCall',tempUserinfo?.caller_id)
						});
						
						//只有触发answer方法，下面这个方法才能被触发
						call.on("stream", (remoteStream:any) => {
							if(otherVideoRef.current.srcObject)return
							console.log('对方给你打视频了',otherVideoRef.current.srcObject,_stream)
							otherVideoRef.current.srcObject = remoteStream
							setIsAccept(true)
							setCalling(false)
						});
						
						console.log('监听到对方了，开启摄像头',myVideoRef.current,myVideoRef.current.srcObject);
						
					}
				).catch(function (error:any) {
					closeAllCall()
					console.error('无法访问摄像头:', error);
					Toast.show('无法访问摄像头！请检查权限或刷新页面重试！')
				});
				setIsOpenCall(true)

			});

		 }
		 return () => {
			console.log('unmount');
		 }
	},[socket]);

	
	return (
		<div>
			{
			isConnect ? 
			<SocketContext.Provider value={{socket, peer,msgList}}>
				{children}
				{isOpenCall ? 
				<Call onClose={closeCall} 
				callInfo={callInfo} type={type} myVideoRef={myVideoRef}
				otherVideoRef={otherVideoRef} calling={calling} isAccept={isAccept} isVideo={isVideo} /> 
				: null}
			</SocketContext.Provider> :
			<div style={{'width':'100vw','height':'100vh','display':'flex','justifyContent':'center','alignItems':'center'}}> 
				<SpinLoading color='rgba(33, 213, 157, 0.8)' />
			</div>
			}
			<div>
				<FloatingBubble
					axis='xy'
					magnetic='x'
					style={{
					'--initial-position-bottom': '64px',
					'--initial-position-right': '24px',
					'--edge-distance': '24px',
					'--background':'rgba(33, 213, 157, 0.8)',
					'--size': '40px',
					}}
					onClick={() => window.location.reload()}
				>
					<RedoOutlined style={{fontSize:'19px'}} />
				</FloatingBubble>
			</div>
		</div>
	);
};



export default WebSocket


// 这个文件 负责发送一些请求，并把结果返回给 reducers 存起来

import { createAsyncThunk} from "@reduxjs/toolkit";
import { loginRequest , registerRequest ,getUserinfoRequest ,
    getOnlineUserRequest , getChatlistRequest,delChatReocrdRequest,
    openaiRequest} from '../api/api'
import { loginProps ,registerProps ,storeState} from '../interface/index'



//登录
export const AsyncLogin = createAsyncThunk<any,loginProps>('/users/login', async ({username,password}) => {
        try {
            const res = await loginRequest('/users/login',{username,password})
            return res.data
        } catch (error:any) {
            return error.response.data
        }
    }
)

//注册
export const AsyncRegister = createAsyncThunk<any,registerProps>('/users/register', async ({username,password,gender}) => {
        try {
            const res = await registerRequest('/users/register',{username,password,gender})
            return res.data
        } catch (error:any) {
            return error.response.data
        }
    }
)

//获取用户信息
export const AsyncGetUserinfo = createAsyncThunk('/users/getUserinfo', async () => {
    try {
        const res = await getUserinfoRequest('/users/getUserinfo')
        return res.data
    } catch (error:any) {
        return error.response.data
    }
})

//获取在线用户
export const AsyncGetOnlineUser = createAsyncThunk('/users/getOnlineUser', async () => {
    try {
        const res = await getOnlineUserRequest('/users/getOnlineUser')
        return res.data
    } catch (error:any) {
        return error.response.data
    }
})

// 获取聊天记录列表
export const AsyncGetChatList = createAsyncThunk<any,any>('/chat/chatlist',async (params) => {
    try {
        const res = await getChatlistRequest('/chat/chatlist',params)
        return res.data
    } catch (error:any){
        return error.response.data
    }
})

//删除聊天记录
export const AsyncDelChatRecord = createAsyncThunk<any,any>('/chat/deletechat',async(params) =>{
   try {
       const res = await delChatReocrdRequest('/chat/deletechat',params.deleteID,params.userID,params.type)
       return res.data
   }catch (e:any) {
        return e.response.data
   }
})

//小寻openai
export const AsyncOpenai = createAsyncThunk<any,string>('/openai',async(params) => {
    try {
        const res = await openaiRequest('/openai?content=' + params)
        return res.data
    } catch (error:any) {
        return error.response.data        
    }
})
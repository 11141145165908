import { ChatListProps, ChatProps } from "../interface";
import { getUserinfoRequest } from "../api/api";
import { formatDate } from "../utils";

const useToast = () => {
  const toast: (chat: ChatProps) => any = async (chat) => {
    //message页面不需要弹出
    if (window.location.href.includes("message")) return;
    const toastDOM = document.createElement("div");

    const result = await getUserinfoRequest(
      "/users/getOtherUserInfo?user_id=" + chat.user_id
    );
    if (result.status === 200) {
      toastDOM.className = "myToast-container";
      const content = chat.msgType === "语音消息" ? "语音消息" : chat.msgType === '图片消息' ? '图片消息' : chat.content;
      toastDOM.innerHTML = `
                <div class="myToast-content fade">
					<div class="myToast-header">寻友   ${formatDate(chat.time)}</div> 
					<div class="myToast-body">
						<div class="myToast-info">
						<h4>${result.data.username}</h4>
						<span>${result.data.username}：${content}</span>
						</div>
						<img src="${result.data.avatar}" />
					</div>
				</div>`;
      const body = document.getElementsByTagName("body")[0];
      body.appendChild(toastDOM);
      const toastBox = document.getElementsByClassName("myToast-container")[0];
      const gotoChathandler = function () {
        window.location.href = "/message/chat";
        sessionStorage.setItem(
          "state",
          JSON.stringify({
            data: {
              ...chat,
              receiver_id: chat.user_id,
              avatar: result.data.avatar,
              title: result.data.username,
            },
            fromPath: window.location.href,
          })
        );
      };
      toastBox.addEventListener("click", gotoChathandler);
      const opacity = 1;
      const clearOpacity = setInterval(() => {
        toastDOM.style.opacity += opacity - 0.45;
      }, 1900);

      const timer = setTimeout(() => {
        if (timer) clearTimeout(timer);
        body.removeChild(toastDOM);
        clearInterval(clearOpacity);
        toastBox.removeEventListener("click", gotoChathandler);
      }, 2000);
    }
  };
  return { toast };
};

export default useToast;

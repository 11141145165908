import { Toast, TabBar } from "antd-mobile";
import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EyeOutline,
  MessageOutline,
  GlobalOutline,
  UserOutline,
} from "antd-mobile-icons";
import { RocketOutlined } from "@ant-design/icons";
import styles from "./layout.module.css";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  updateUnreadMsg,
} from "../../store/reducers/UserReducer";
import { SocketContext } from "../../Socket";
import { AsyncGetChatList } from "../../store/actionAPI";

const Bottom: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { socket }: any = useContext(SocketContext);
  const { pathname } = location;
  const dispatch = useAppDispatch();
  const userinfo = useAppSelector((state) => state.user.userinfo);
  const unread_num = useAppSelector((state) => state.user.unreadMsg);

  useEffect(() => {
    // if (!checkLogin(pathname)) {
    //   Toast.show("请先登录！");
    //   dispatch(recordPreviousPath(pathname));
    //   navigate("/login");
    // }
    // if (socket) {
    //   socket.on("message", (msg: any) => {
    //     dispatch(
    //       AsyncGetChatList({ userID: msg.receiver_id, receiverID: null })
    //     ).then((res) => {
    //       dispatch(updateUnreadMsg(res.payload.data));
    //     });
    //   });
    // }
  }, []);

  const gotoPage = (value: string) => {
    navigate(value);
  };

  //判断跳转的页面是否是me
  const getPagePath = (key: string): string => {
    // if (key == "/me" && userinfo?.user_id) {
    //   return "/me/" + userinfo.user_id;
    // }
    return key;
  };

  const Menus = [
    {
      key: getPagePath("/"),
      title: "首页",
      icon: <GlobalOutline />,
    },
    {
      key: getPagePath("/openai"),
      title: "小寻",
      icon: <EyeOutline />,
      // selectedIcon: (
      //   <div
      //     style={{
      //       width: "22px",
      //       height: "22px",
      //       background:
      //         "url(https://img0.baidu.com/it/u=2136891175,1458697858&fm=253&fmt=auto&app=138&f=JPEG?w=889&h=500) center center /  21px 21px no-repeat",
      //     }}
      //   ></div>
      // ),
    },
    {
      key: getPagePath("/mate"),
      title: "在线匹配",
      icon: <RocketOutlined />,
    },
    {
      key: getPagePath("/message"),
      title: "消息",
      icon: <MessageOutline />,
      badge: unread_num,
    },
    {
      key: getPagePath("/me"),
      title: "我的",
      icon: <UserOutline />,
    },
  ];

  //判断是否显示tabbar
//   const isShowTabBar = (pathname: string): boolean => {
//     return Menus.some((menu) => {
//       //小寻页面不显示tabbar
//       if (pathname === "/openai") {
//         return false;
//       }
//       return menu.key.includes(pathname);
//     });
//   };

  return (
    <TabBar activeKey={pathname} onChange={(value) => gotoPage(value)}>
      {Menus.map((item) => (
            <TabBar.Item
                key={item.key}
                icon={item.icon}
                title={item.title}
                badge={item.badge}
            />
      ))}
    </TabBar>
  )
};
const Layout: React.FC = () => {
  return (
      <div className={styles.bottom}>
        <Bottom />
      </div>
  );
};

export default Layout;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { config as AmapReactConfig } from '@amap/amap-react';
import './index.css';
import App from './App';
import { store } from './store';
import WebSocket from './Socket';

AmapReactConfig.version = '2.0'; // 默认2.0，这里可以不修改
AmapReactConfig.key = 'ec3c08bf1c5101cc9623eeb590722047';
// AmapReactConfig.plugins = [
//     'AMap.ToolBar',
//     'AMap.MoveAnimation',
//     // 在此配置你需要预加载的插件，如果不配置，在使用到的时候会自动异步加载
// ];

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <WebSocket>
      <App />
    </WebSocket>
		{/* <React.StrictMode> */}
		{/* </React.StrictMode> */}
  </Provider>
);

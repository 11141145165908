import { useState, useEffect,useRef} from "react";
import {CallProps} from "../..//interface";
import {Toast} from "antd-mobile";
import styles from "./call.module.css"
import audioSrc from "../../assets/music/call.mp3"

let beginTiming:any = null
let vibrateTimer:any = null
const Call = function(props:CallProps){
    const { callInfo, onClose , type ,myVideoRef ,otherVideoRef ,calling,isAccept,isVideo} = props
	const audioRef = useRef<HTMLAudioElement>(null)
	const [deadline,setDeadline] = useState(0)
	console.log('Call',calling,props)

	useEffect(() => {
		if(audioRef.current){
			// 浏览器自动播放策略影响，需要提前设置静音，否则浏览器会报错
			audioRef.current.muted = true
			const ctx = new AudioContext()
			const canAutoPlay = ctx.state === 'running';
			ctx.close()
			if(canAutoPlay){
				audioRef.current.muted = false
			}
			
		}
		if(isAccept && !calling){
			// 关闭背景音乐
			audioRef.current?.pause()
			if("virbrate" in navigator){
				navigator.vibrate(0);
			}
			clearInterval(beginTiming)
			clearTimeout(vibrateTimer)
			return
		}
		 beginTiming = setInterval(() => {
			// 超过一分钟自动挂断
			if(deadline === 60){
				hangUp('对方暂时无法接听')
			}
			setDeadline((time) => time + 1)
		}, 1000);

		if ("vibrate" in navigator) {
			navigator.vibrate(1000);
			vibrateTimer = setTimeout(() => {
				navigator.vibrate(0);
				clearTimeout(vibrateTimer)
			}, 500);
		} else {
			console.log("Vibration API not supported");
		}
		
		return () => {
			clearInterval(beginTiming)
			clearTimeout(vibrateTimer)
		}
	},[deadline,isAccept,calling])

	
	//挂断电话
	const hangUp = (title:string = '你已挂断电话') => {
		clearInterval(beginTiming)
		Toast.show(title)
		// 关闭背景音乐
		audioRef.current?.pause()
		onClose()
	}
// 
	const mutedCall = () => {
		console.log('开启静音');
		
		(myVideoRef?.current.srcObject as any).getTracks().forEach((track: MediaStreamTrack) => {
			if (track.kind == 'audio') {
				track.enabled = !track.enabled
			}
		})
	}
	const closeCamera = () => {
		console.log('关闭摄像头');
		
		(myVideoRef?.current.srcObject as any).getTracks().forEach((track: MediaStreamTrack) => {
			if (track.kind == 'video') {
				track.enabled = !track.enabled
			}
		})
	}

	return <div className={styles.call} id="videoCon">
		<video id='myVideo' autoPlay  playsInline ref={myVideoRef}></video>
		<video id={styles.otherVideo}  playsInline autoPlay ref={otherVideoRef}></video>
		<audio id="audio" ref={audioRef} playsInline loop src={audioSrc} autoPlay />
		<div className={styles.call_con}>
			{
				type === 'self' ?
				<>
					<div className={styles.call_info}>
						{
							calling ?
							<>
								<img src={callInfo.receiver_avatar} alt={callInfo.receiver_name} />
								<h3>{callInfo.receiver_name}</h3>
								<span>
									{
										isVideo ? '正在邀请对方视频通话...' : '正在邀请对方语音通话...'
									}
								</span>
							</>:
							<>
								{
									isVideo ? null :
									<>
										<img src={callInfo.receiver_avatar} alt={callInfo.receiver_name} />
										<h3>{callInfo.receiver_name}</h3>
									</>
								}
							</>
						}
					</div>
					<div className={styles.call_btns}>
						{
							!calling ? <Timer/> :null
						}
						<div className={styles.call_btns_item} onClick={mutedCall}>
							<div className={'iconfont ' + styles.muted}>&#xe817;</div>
							<span>静音</span>
						</div>
						<div className={styles.call_btns_item} onClick={() => hangUp()}>
							<div className={'iconfont ' + styles.reject}>&#xe640;</div>
							<span>挂断</span>
						</div>
						{
							isVideo ?
							<div className={styles.call_btns_item} onClick={closeCamera}>
								<div className={'iconfont ' + styles.accept}>&#xe608;</div>
								<span>关闭摄像头</span>
							</div>:
							<div className={styles.call_btns_item} >
								<div className="iconfont ">&#xe641;</div>
								<span>已开启麦克风</span>
							</div>
						}
					</div>
				</> :
				<>
					<div className={styles.call_info}>
						{
							!isAccept ?
							<>
								<img src={callInfo.caller_avatar} alt={callInfo.caller_name} />
								<h3>{callInfo.caller_name}</h3>
								<span>
									{
										isVideo ? '对方正在邀请您视频通话...' : '对方正在邀请您语音通话...'
									}
								</span>
							</>:
							<>
								{
									isVideo ? null :
									<>
										<img src={callInfo.caller_avatar} alt={callInfo.caller_name} />
										<h3>{callInfo.caller_name}</h3>
									</>
								}
							</>
						}
					</div>
					<div className={styles.call_btns}>
						{
							isAccept ? <Timer/> :null
						}
						<div className={styles.call_btns_item} onClick={mutedCall}>
							<div className={'iconfont ' + styles.muted}>&#xe817;</div>
							<span>静音</span>
						</div>
						{
							isAccept ?
							<div className={styles.call_btns_item} onClick={() => hangUp()}>
								<div className={'iconfont ' + styles.reject}>&#xe640;</div>
								<span>挂断</span>
							</div>:
							<div className={styles.call_btns_item} id='closeCall'>
								<div className={'iconfont ' + styles.reject}>&#xe640;</div>
								<span>拒绝</span>
							</div>
						}
						{
							isAccept ? 
								isVideo ? 
								<div className={styles.call_btns_item} onClick={closeCamera}>
									<div className={'iconfont ' + styles.accept}>&#xe608;</div>
									<span>关闭摄像头</span>
								</div>:
								<div className={styles.call_btns_item}>
									<div className="iconfont">&#xe641;</div>
									<span>已开启麦克风</span>
								</div>
							:
							<div className={styles.call_btns_item} id="answerCall">
								<div className={'iconfont ' + styles.accept}>&#xe641;</div>
								<span>接听</span>
							</div>
						}
					</div>
				</> 
			}
		</div>
	
	</div>
}

// 格式化显示 通话时长
function Timer () {
	const [seconds, setSeconds] = useState(0);
	const [minute, setMinute] = useState(0);
  
	useEffect(() => {
		let secondsNumber = seconds
	  	const interval = setInterval(() => {
		  
			if(secondsNumber >= 60){
				setMinute(preMinute => preMinute + 1)
				setSeconds(0)
				secondsNumber = 0
			}else{
				setSeconds(prevSeconds => prevSeconds + 1);
				secondsNumber++
			}
	    }, 1000);
  
	  return () => {
		clearInterval(interval);
	  };
	}, []);
  
	return <div className={styles.duration}>
		<div> {minute < 10 ? '0'+ minute : minute}:{seconds < 10 ? '0'+ seconds : seconds} </div>
	</div>;
};


export default Call
import React, { lazy } from "react";
const Home = lazy(() => import('../pages/Home/index'))
const Details = lazy(() => import('../pages/Home/Details'))
const Openai = lazy(() => import('../pages/Openai/index'))
const Me = lazy(() => import('../pages/Me/index'))
const NotFound = lazy(() => import('../pages/NotFound/index'))
const Edit = lazy(() => import('../pages/Me/Edit'))
const Message = lazy(() => import('../pages/Message/index'))
const Chat = lazy(() => import('../pages/Message/Chat'))
const Publish = lazy(() => import('../pages/Publish/index'))
const Login = lazy(() => import('../components/Login/index'))
const Register = lazy(() => import('../components/Register/index'))
const Mate = lazy(() => import('../pages/Mate/index'))

interface metaProps{
    keepActive?: boolean,
    auth?: boolean,
    tarbar?: boolean,
}
export interface iRouteProps {
    path: string,
    element: React.LazyExoticComponent<any>,
    title: string,
    meta: metaProps,
    children?:iRouteProps[]
}

const routes:iRouteProps[] = [
    {
        path:'/',
        element: Home,
        title: '首页',
        meta:{
            auth: false,
            tarbar: true,
        },
        children: [
            {
                path: '/post/detail/:id',
                element: Details,
                title: '帖子详情',
                meta: {
                    auth: false,
                    tarbar: false,
                }
            }
        ]
    },
    {
        path: '/openai',
        element: Openai,
        title: '小寻',
        meta: {
            auth: false,
            tarbar: false,
        }
    },
    {
        path:'/me',
        element: Me,
        title: '我的主页',
        meta:{
            auth: true,
            tarbar: true,
        },
        children: [
            {
                path:'/me/:user_id',
                element: Me,
                title: '其他人主页',
                meta:{
                    auth: false,
                    tarbar: false,
                },
            },
            {
                path: '/me/edit',
                element: Edit,
                title: '编辑个人资料',
                meta: {
                    auth: true,
                    tarbar: false,
                },
            }
        ]
    },
    {
        path:'/message',
        element: Message,
        title: '消息',
        meta:{
            auth: true,
            tarbar: true,
        },
        children: [
            {
                path: '/message/chat',
                element: Chat,
                title: '聊天',
                meta: {
                    auth: true,
                    tarbar: false,
                }
            }
        ]
    },
    {
        path:'/publish',
        element: Publish,
        title: '发布',
        meta:{
            auth: true,
            tarbar: false,
        },
    },
    {
        path:'/mate',
        element: Mate,
        title: '在线匹配',
        meta:{
            auth: true,
            tarbar: false,
        },
    },
    {
        path:'/login',
        element: Login,
        title: '登录',
        meta:{
            auth: true,
            tarbar: false,
        },
    },
    {
        path:'/register',
        element: Register,
        title: '注册',
        meta:{
            auth: true,
            tarbar: false,
        },
    },
    {
        path:'/*',
        element: NotFound,
        title: 'not found',
        meta:{
            auth: false,
            tarbar: false,
        },
    },
]

export default routes


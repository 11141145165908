
import React,{ Suspense, useCallback, useEffect, useMemo } from "react";
import { Routes, Route ,useLocation ,matchPath, Navigate} from "react-router-dom";
import { Empty ,Spin } from "antd";
import { iRouteProps } from "./routes";
import { HomeSkeleton , MessageSkeleton } from "../components/Skeleton/index"
import { getToken } from "../utils/index"
import Layout from "../components/Layout/index"
import routes from '../router/routes';
import Login from "../components/Login";


// 扁平化路由
const flatRouter = (target:iRouteProps[]) => {
    
    const result:iRouteProps[] = []
    target.forEach(item => {
        const { path , element , title , meta} = item
        if(item.children){
            result.push(...flatRouter(item.children))
        }
        result.push({
            path,element,title,meta
        })
    })
    return result
}


// 渲染对应路由的骨架屏
const RenderRouter:React.FC = () => {
    const location = useLocation()
    const [loading,setLoading] = React.useState(true)
    console.log(123456);
    
    // 根据路由渲染骨架屏
    const renderSkeleton:() => JSX.Element = () => {
        
        let skeleton:JSX.Element =  <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh'}}>
        <Empty description={false}></Empty>
        <Spin spinning={loading} fullscreen />
    </div>
        
        switch (location.pathname) {
            case '/':
                skeleton = (
                    <>
                        <HomeSkeleton/>
                        <Layout/>
                    </>
                )
                break;
            case '/message':
                skeleton = (
                    <>
                        <MessageSkeleton/>
                        <Layout/>
                    </>
                )
                break;
            default:
                if(location.pathname.includes('/me')){
                    skeleton = (
                        <>
                            <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'70vh'}}>
                                <Empty description={false}></Empty>
                                <Spin spinning={loading} fullscreen />
                            </div>
                            <Layout/>
                        </>
                    )
                }
               
                break;
        }
        return skeleton
    }

    // 路由守卫+权限守卫
    const beforeEnterRouter = (route:iRouteProps) => {

        const { meta:{auth,tarbar},element,path} = route
        const Page = element

        if(matchPath(path,window.location.pathname)){
            
            // 没有登录
           if(auth && !getToken() && !path.includes('register')){
                // Toast.show('请先登录')
                return <Navigate to="/login" replace />
           }else if(getToken() && (path === '/login' || path === '/register')){
                return <Navigate to="/" replace />
           }
            // 显示底部导航
           if(tarbar){
                return (
                    <>
                        <Page/>
                        <Layout/>
                    </>
                )
            }
            return <Page/>
        }
        
    }
    // 缓存处理
    const memoizedRoutes  = useMemo(() => flatRouter(routes),[routes])

    useEffect(() => {
        
    })
    
    return (
        <Suspense fallback={renderSkeleton()}>
            <Routes>
                <Route path='/login' element={<Login/>}></Route>
                {
                    memoizedRoutes.map(item => {
                        return <Route key={item.path} path={item.path} element={beforeEnterRouter(item)}></Route>
                    })
                }
            </Routes>
        </Suspense>
    )
}


 export default RenderRouter
import React,{useRef,useState , useEffect} from 'react'
import { Link, useNavigate ,useLocation} from 'react-router-dom'
import { PayloadAction } from "@reduxjs/toolkit";
import { Input,Button,Toast } from 'antd-mobile'
import { setToken ,getToken} from '../../utils'
import styles from './login.module.css'
import { useAppSelector,useAppDispatch } from '../../store'
import { AsyncLogin , AsyncGetUserinfo} from "../../store/actionAPI"
import { storeState } from '../../interface';
import logoImg from '../../assets/logo.png'
import textLogoImg from '../../assets/logo-text.png'

const Login:React.FC = () => {
    const [username,setUsername] = useState<string>()
    const [password,setPassword] = useState<string>()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const pathname = useAppSelector(state => state.user.pathname)
    
    useEffect(() => {
        if(getToken()){
            navigate(pathname)
        }
    })
        const userLogin = async () => {
           if(username && password){
                const { payload } =  await dispatch<PayloadAction<storeState>>( AsyncLogin({username,password}) as any )
                if(payload && payload?.token){
                    setToken(payload.token)
                    // navigate(pathname)
                    
                    navigate(pathname,{
                        state: {
                            from: 'login'
                        }
                    })
                    // 刷新页面 连接socket
                    window.location.reload();
                    // await dispatch(AsyncGetUserinfo())
                   
                }else{
                   Toast.show(payload?.msg as any || '登录失败')
                }
           }else{
            Toast.show('输入完整登录信息！')
           }
        }
        
   
    return (
        <div className={styles.con}> 
            {/* 标题 */}
            <div className={styles.title}>
                <Link to="/register">注册</Link>
            </div>
            {/* 版心内容 */}

            <div className={styles.content}>
                {/* logo */}
                <div className={styles.logo}>
                    <img src={logoImg} alt="" />
                    <img className={styles.text} src={textLogoImg} alt="" />
                    <span>快来寻找属于你的朋友</span>
                </div>
                {/* 表单 */}
                <div className={styles.form}>
                    <Input className={styles['adm-input-element']} value={username} onChange={val=>setUsername(val)} placeholder="输入用户名"/>
                    <Input className={styles['adm-input-element']} value={password} onChange={val=>setPassword(val)} placeholder="输入密码" type="password"/>
                    <Button onClick={userLogin}>登录</Button>
                    <div className={styles.tip}>
                        <a href="/">忘记密码</a>
                        <a href="/">其他登录</a>
                    </div>
                </div>
                
            </div>

            <div className={styles.icp}>
                <a href="https://beian.mps.gov.cn/#/query/webSearch">ICP主体备案号 豫ICP备2024042778号</a>
            </div>
        </div>
    )
}

export default Login
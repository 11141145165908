import axiosInstance from "./index"
import { loginProps ,registerProps } from "../interface"


//登录
export const loginRequest = (url:string,params:loginProps) => {
	return axiosInstance.post(url,params)
}

//注册
export const registerRequest = (url:string,params:registerProps) => {
	return axiosInstance.post(url,params)
}

//获取用户信息
export const getUserinfoRequest = (url:string) => {
	return axiosInstance.get(url)
}

//获取在线用户
export const getOnlineUserRequest = (url:string) => {
	return axiosInstance.get(url)
}

//上传音频接口
export const uploadAudioRequest = (url:string,formData:FormData) => {
	return axiosInstance.post(url,formData)
}

//上传图片接口
export const uploadPhotoRequest = (url:string,formData:FormData) => {
	return axiosInstance.post(url,formData)
}

// 获取聊天记录列表
interface configProps{
	chatID?:number
	userID:number
	receiverID:number|null
}
export const getChatlistRequest = (url:string,config:configProps) => {
	const {chatID,userID,receiverID} = config
	if(chatID){
		return axiosInstance.get(`${url}?user_id=${userID}&chat_id=${chatID}`)
	}
	if(receiverID){
		return axiosInstance.get(`${url}?user_id=${userID}&receiver_id=${receiverID}`)
	}
	return axiosInstance.get(`${url}?user_id=${userID}`)
}

// 同步聊天记录
type synChatRecordProps = {
	chatID:number,
	ID:number
}
export const synChatRecordRequest = (url:string,params:synChatRecordProps) => {
	const {chatID,ID} = params
	return axiosInstance.get(`${url}?chat_id=${chatID}&id=${ID}`)
}

// 删除聊天记录
export const delChatReocrdRequest = (url:string,deleteID:number,userID:number,type:number) => {
	return axiosInstance.post(url, {deleteID,userID,type})
}

//小寻 openai
export const openaiRequest =  (url:string) => {
	// const source = new EventSource("http://localhost:3000/api" + url);
	// source.onmessage = (event) => {
	// 	console.log('Received data from OpenAI:', event.data);
	// 	return event.data
	// };
	  
	//   source.onerror = (error) => {
	// 	console.error('Error receiving SSE event:', error);
	//   };

	return axiosInstance.get(url)
}
// payloadaction 是redux-toolkit中的一个类型，用于定义 action 的结构，以及传递给reducer的数据
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Toast } from "antd-mobile";
import { storeState, ChatListProps, ChatProps } from "../../interface/index";
import { setToken , updateUserinfo } from "../../utils";
import {
  AsyncLogin,
  AsyncRegister,
  AsyncGetUserinfo,
  AsyncGetOnlineUser,
  AsyncGetChatList,
} from "../actionAPI";

// 全局的store
const initialState: storeState = {
  token: null, // 记录token
  pathname: "/", //记录上一次的路径
  userinfo: {}, //记录用户信息
  online: true, //记录在线状态
  onlineUsers: [], //记录在线用户
  chatlist: [], //记录最新聊天记录
  videoElementInfo: {}, //记录video元素信息
  unreadMsg: "", //记录未读消息
  msglist: [], //记录信息列表
};

type unreadMsgProps = {
  payload: any;
};

const UserReducerSlice = createSlice({
  name: "UserReducer",
  initialState,
  reducers: {
    //记录拦截的路由
    recordPreviousPath(state, action) {
      state.pathname = action.payload;
    },
    //记录是否有人上线
    updateOnline(state, action) {
      state.online = action.payload;
    },
    recordVideoElementInfo(state, action) {
      console.log("成功", action.payload);
      state.videoElementInfo = action.payload;
    },

    updateUnreadMsg(state, action: unreadMsgProps) {
      let count = 0
      if(action.payload[0]?.total){
          count = action.payload.reduce((pre:number, cur:any) => {
            return (pre += cur.unread_num);
          }, 0);
      }else{
        count = action.payload.reduce((pre:number, cur:any) => {
          if(cur.isRead === '未读'){
            return pre += 1
          }
          return pre;
        }, 0);
      }
      console.log('action', action.payload,count);
      state.unreadMsg = count === 0 ? "" : String(count);
    },
  },
  //这里可以在发送请求的时候做一些事
  extraReducers: (builder) => {
    builder
      .addCase(
        AsyncLogin.fulfilled,
        (state, action: PayloadAction<storeState>) => {
          if (action.payload.token) {
            state.token = action.payload.token;
            Toast.show(action.payload.msg as any);
          }
        }
      )
      .addCase(
        AsyncRegister.fulfilled,
        (state, action: PayloadAction<storeState>) => {
          if (action.payload.token) {
            state.token = action.payload.token;
            setToken(action.payload.token);
            Toast.show(action.payload.msg as any);
          }
        }
      )
      .addCase(
        AsyncGetUserinfo.fulfilled,
        (state, action: PayloadAction<any>) => {
          if(action.payload.data){
            updateUserinfo(action.payload.data)
          }
          state.userinfo = action.payload.data;
        }
      )
      .addCase( // 在线用户信息
        AsyncGetOnlineUser.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.onlineUsers = action.payload;
        }
      )
      .addCase( //聊天列表
        AsyncGetChatList.fulfilled,
        (state, action: PayloadAction<any,any,any>) => {
          console.log(action.payload.data);
          state.msglist = [...action.payload.data];
        }
      )
  },
});

export const {
  recordPreviousPath,
  updateOnline,
  recordVideoElementInfo,
  updateUnreadMsg,
} = UserReducerSlice.actions;
export default UserReducerSlice.reducer;
